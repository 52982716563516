import _slicedToArray from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { createSubdomainUrl } from '@webapp/common/lib/utils';
import { LogicAction, LogicBoolFunc, LogicTransition, LogicTransitionType, LogicType, QuestionType, withClickReply } from '@webapp/common/resources/constants';
import { BundleFinishType, BundleType } from '@webapp/common/resources/entities';
import { BundleEntity } from '@webapp/survey-new/src/entities/bundle';
import { getQ } from '@webapp/survey-new/src/machine/lib';

var getGroupsValues = function getGroupsValues(groups) {
  return groups.reduce(function (acc, _ref) {
    var responses = _ref.responses;
    var entries = Object.keys(responses).map(function (rKey) {
      return responses[rKey];
    }).map(function (r) {
      return r.getSnapshot().context.value;
    });

    if (entries.length > 0) {
      acc.push(entries);
    }

    return acc;
  }, []);
};

var TEXT_FINISH = new Set([LogicTransition.PERSONAL_COMPLETE, LogicTransition.DISQUAL, LogicTransition.PAGES, LogicTransition.QUESTIONS]);

var createLastBundle = function createLastBundle(_ref2) {
  var completeText = _ref2.completeText,
      disqualText = _ref2.disqualText,
      toSurveyUrl = _ref2.toSurveyUrl,
      toWebsite = _ref2.toWebsite,
      transition = _ref2.transition,
      transitionType = _ref2.transitionType;
  var lastBundle = new BundleEntity({
    random: false,
    questions: [],
    finishType: function () {
      switch (transition) {
        case LogicTransition.PERSONAL_COMPLETE:
          return BundleFinishType.STOP;

        case LogicTransition.DISQUAL:
          return BundleFinishType.DISQUAL;

        default:
          return BundleFinishType.STOP;
        // TODO FIXME not used
      }
    }()
  }, []);
  if (!TEXT_FINISH.has(transition)) return lastBundle;

  switch (transitionType) {
    case LogicTransitionType.TEXT:
      lastBundle.type = BundleType.TEXT;
      lastBundle.text = [LogicTransition.PERSONAL_COMPLETE, LogicTransition.PAGES, LogicTransition.QUESTIONS].includes(transition) ? completeText : disqualText;
      break;

    case LogicTransitionType.TO_SURVEY:
      lastBundle.type = BundleType.REDIRECT_SURVEY;
      lastBundle.redirect = createSubdomainUrl(toSurveyUrl);
      break;

    case LogicTransitionType.REDIRECT_TO_WEBSITE:
      lastBundle.type = BundleType.REDIRECT_URL;
      lastBundle.redirect = toWebsite;
      break;
  }

  return lastBundle;
};

var logicMatch = function logicMatch(logic, groups, answers, response, questionCtx, quotaByQuestionId) {
  var boolFunc = logic.boolFunc,
      params = logic.params,
      type = logic.type;
  var questionType = questionCtx.type;
  var notScale = questionType !== QuestionType.SCALE; // TODO fix backend, dont use groups

  var withGroups = Boolean(notScale && groups && groups.length > 0);
  var withAnswers = Boolean(notScale && answers && answers.length > 0);
  var variantsIds = params.reduce(function (acc, _ref3) {
    var id = _ref3.id;
    return acc[id] = true, acc;
  }, {}); // TODO use Set

  var answersById = answers.reduce(function (acc, a) {
    return acc[a.id] = a, acc;
  }, {});
  var satisfied = false;

  switch (type) {
    case LogicType.TYPE_QUESTION_QUOTA:
      {
        var isSomeOfAnswersSelected = false;

        if (questionType === QuestionType.MATRIX_STAR) {
          isSomeOfAnswersSelected = Object.keys(variantsIds).some(function (id) {
            var _answersById$answerId, _answersById$answerId2;

            var _id$toString$split = id.toString().split('-'),
                _id$toString$split2 = _slicedToArray(_id$toString$split, 3),
                _qId = _id$toString$split2[0],
                rating = _id$toString$split2[1],
                answerId = _id$toString$split2[2];

            return ((_answersById$answerId = answersById[answerId]) === null || _answersById$answerId === void 0 ? void 0 : (_answersById$answerId2 = _answersById$answerId.response.getSnapshot().context.value) === null || _answersById$answerId2 === void 0 ? void 0 : _answersById$answerId2.toString()) === rating;
          });
        } else if (questionType === QuestionType.MATRIX_SINGLE_ANSWER || questionType === QuestionType.MATRIX_FEW_ANSWERS) {
          isSomeOfAnswersSelected = Object.keys(variantsIds).some(function (id) {
            var _groups$find, _groups$find$response;

            var _id$toString$split3 = id.toString().split('-'),
                _id$toString$split4 = _slicedToArray(_id$toString$split3, 3),
                _qId = _id$toString$split4[0],
                groupId = _id$toString$split4[1],
                answerId = _id$toString$split4[2];

            return !!((_groups$find = groups.find(function (gr) {
              return gr.id.toString() === groupId;
            })) !== null && _groups$find !== void 0 && (_groups$find$response = _groups$find.responses[answerId]) !== null && _groups$find$response !== void 0 && _groups$find$response.getSnapshot().context.value);
          });
        } else if (withGroups) {
          var values = getGroupsValues(groups);
          isSomeOfAnswersSelected = values.some(function (answerId) {
            return variantsIds[answerId];
          });
        } else if (withAnswers) {
          isSomeOfAnswersSelected = answers.some(function (_ref4) {
            var id = _ref4.id,
                response = _ref4.response;
            return !!response.getSnapshot().context.value && variantsIds[id];
          });
        } else {
          isSomeOfAnswersSelected = !!variantsIds[response.value];
        }

        satisfied = quotaByQuestionId[logic.id] === false && isSomeOfAnswersSelected;
        break;
      }

    case LogicType.SELECT_VARIANTS:
      switch (boolFunc) {
        case LogicBoolFunc.AND:
          if (questionType === QuestionType.MATRIX_STAR) {
            var groupedByRowId = Object.keys(variantsIds).reduce(function (acc, id) {
              var _id$toString$split5 = id.toString().split('-'),
                  _id$toString$split6 = _slicedToArray(_id$toString$split5, 3),
                  _qId = _id$toString$split6[0],
                  rating = _id$toString$split6[1],
                  rowId = _id$toString$split6[2];

              if (!acc[rowId]) {
                acc[rowId] = [{
                  rowId: rowId,
                  rating: rating
                }];
              } else {
                acc[rowId].push({
                  rowId: rowId,
                  rating: rating
                });
              }

              return acc;
            }, {});
            satisfied = Object.keys(groupedByRowId).every(function (rowId) {
              var rowAnswers = groupedByRowId[rowId];
              return rowAnswers.some(function (rowAns) {
                var _answersById$rowId, _answersById$rowId$re, _answersById$rowId$re2;

                return ((_answersById$rowId = answersById[rowId]) === null || _answersById$rowId === void 0 ? void 0 : (_answersById$rowId$re = _answersById$rowId.response) === null || _answersById$rowId$re === void 0 ? void 0 : (_answersById$rowId$re2 = _answersById$rowId$re.getSnapshot().context.value) === null || _answersById$rowId$re2 === void 0 ? void 0 : _answersById$rowId$re2.toString()) === rowAns.rating;
              });
            });
          } else if (questionType === QuestionType.MATRIX_SINGLE_ANSWER || questionType === QuestionType.MATRIX_FEW_ANSWERS) {
            var _groupedByRowId = Object.keys(variantsIds).reduce(function (acc, id) {
              var _id$toString$split7 = id.toString().split('-'),
                  _id$toString$split8 = _slicedToArray(_id$toString$split7, 3),
                  _qId = _id$toString$split8[0],
                  groupId = _id$toString$split8[1],
                  rowId = _id$toString$split8[2];

              if (!acc[rowId]) {
                acc[rowId] = [{
                  rowId: rowId,
                  groupId: groupId
                }];
              } else {
                acc[rowId].push({
                  rowId: rowId,
                  groupId: groupId
                });
              }

              return acc;
            }, {});

            satisfied = Object.keys(_groupedByRowId).every(function (rowId) {
              var rowAnswers = _groupedByRowId[rowId];
              return rowAnswers.some(function (rowAns) {
                var _groups$find2, _groups$find2$respons;

                return !!((_groups$find2 = groups.find(function (gr) {
                  return gr.id.toString() === rowAns.groupId;
                })) !== null && _groups$find2 !== void 0 && (_groups$find2$respons = _groups$find2.responses[rowAns.rowId]) !== null && _groups$find2$respons !== void 0 && _groups$find2$respons.getSnapshot().context.value);
              });
            });
          } else if (withGroups) {// const values = getGroupsValues(groups);
            // satisfied = values.some((answerId) => variantsIds[answerId]);
          } else if (withAnswers) {
            satisfied = Object.keys(variantsIds).every(function (id) {
              var _answersById$id;

              return !!((_answersById$id = answersById[id]) !== null && _answersById$id !== void 0 && _answersById$id.response.getSnapshot().context.value);
            });
          }

          break;

        case LogicBoolFunc.OR:
          if (questionType === QuestionType.MATRIX_STAR) {
            satisfied = Object.keys(variantsIds).some(function (id) {
              var _answersById$answerId3, _answersById$answerId4;

              var _id$toString$split9 = id.toString().split('-'),
                  _id$toString$split10 = _slicedToArray(_id$toString$split9, 3),
                  _qId = _id$toString$split10[0],
                  rating = _id$toString$split10[1],
                  answerId = _id$toString$split10[2];

              return ((_answersById$answerId3 = answersById[answerId]) === null || _answersById$answerId3 === void 0 ? void 0 : (_answersById$answerId4 = _answersById$answerId3.response.getSnapshot().context.value) === null || _answersById$answerId4 === void 0 ? void 0 : _answersById$answerId4.toString()) === rating;
            });
          } else if (questionType === QuestionType.MATRIX_SINGLE_ANSWER || questionType === QuestionType.MATRIX_FEW_ANSWERS) {
            satisfied = Object.keys(variantsIds).some(function (id) {
              var _groups$find3, _groups$find3$respons;

              var _id$toString$split11 = id.toString().split('-'),
                  _id$toString$split12 = _slicedToArray(_id$toString$split11, 3),
                  _qId = _id$toString$split12[0],
                  groupId = _id$toString$split12[1],
                  answerId = _id$toString$split12[2];

              return !!((_groups$find3 = groups.find(function (gr) {
                return gr.id.toString() === groupId;
              })) !== null && _groups$find3 !== void 0 && (_groups$find3$respons = _groups$find3.responses[answerId]) !== null && _groups$find3$respons !== void 0 && _groups$find3$respons.getSnapshot().context.value);
            });
          } else if (withGroups) {
            var _values = getGroupsValues(groups);

            satisfied = _values.some(function (answerId) {
              return variantsIds[answerId];
            });
          } else if (withAnswers) {
            satisfied = answers.some(function (_ref5) {
              var id = _ref5.id,
                  response = _ref5.response;
              return !!response.getSnapshot().context.value && variantsIds[id];
            });
          } else {
            satisfied = !!variantsIds[response.value];
          }

          break;
      }

      break;

    case LogicType.NOT_SELECT_VARIANTS:
      switch (boolFunc) {
        case LogicBoolFunc.AND:
          if (withGroups) {// const values = getGroupsValues(groups);
            // satisfied = !values.every((answerId) => variantsIds[answerId]);
          } else if (withAnswers) {
            satisfied = Object.keys(variantsIds).every(function (id) {
              var _answersById$id2, _answersById$id2$resp;

              return !((_answersById$id2 = answersById[id]) !== null && _answersById$id2 !== void 0 && (_answersById$id2$resp = _answersById$id2.response) !== null && _answersById$id2$resp !== void 0 && _answersById$id2$resp.getSnapshot().context.value);
            });
          } else {}

          break;

        case LogicBoolFunc.OR:
          if (withGroups) {
            var _values2 = getGroupsValues(groups);

            satisfied = !_values2.every(function (answerId) {
              return variantsIds[answerId];
            });
          } else if (withAnswers) {
            if (withClickReply(questionCtx)) {
              satisfied = answers.filter(function (_ref6) {
                var id = _ref6.id;
                return variantsIds[id];
              }).every(function (_ref7) {
                var response = _ref7.response;
                return !response.getSnapshot().context.value;
              });
            } else {
              satisfied = answers.some(function (_ref8) {
                var id = _ref8.id,
                    response = _ref8.response;
                return variantsIds[id] && !response.getSnapshot().context.value;
              });
            }
          } else {
            satisfied = !variantsIds[response.value];
          }

          break;
      }

      break;

    case LogicType.SKIP:
      if (!response.value) {
        satisfied = true;
      }

      if (withAnswers) {
        satisfied = answers.every(function (_ref9) {
          var response = _ref9.response;
          return !response.getSnapshot().context.value;
        });
      }

      if (withGroups) {
        satisfied = groups.every(function (_ref10) {
          var responses = _ref10.responses;
          var empty = true;

          for (var _i = 0, _Object$keys = Object.keys(responses); _i < _Object$keys.length; _i++) {
            var _key = _Object$keys[_i];

            var _response = responses[_key].getSnapshot().context;

            if (_response.value) {
              empty = false;
              break;
            }
          }

          return empty;
        });
      }

      break;

    case LogicType.ALWAYS:
      satisfied = true;
      break;
  }

  return satisfied;
};

var adjustQuestions = function adjustQuestions(bundles, shownByLogic) {
  var questionOrder = 0;
  bundles.forEach(function (_ref11) {
    var questions = _ref11.questions;
    questions.forEach(function (q) {
      q.send({
        type: 'SET_SHOW_BY_LOGIC',
        value: shownByLogic
      });

      if (q.getSnapshot().context.type !== QuestionType.TEXT_BLOCK) {
        q.send({
          type: 'SET_ORDER',
          value: ++questionOrder
        });
      }
    });
  });
};

var checkIsAllQuestionsHideByCommonLogic = function checkIsAllQuestionsHideByCommonLogic(bundles, logics) {
  var commonQuestionsLogicMatches = logics.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.QUESTIONS && logic.action === LogicAction.SHOW;
  });
  var commonPagesLogicMatches = logics.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.PAGES && logic.action === LogicAction.SHOW;
  });
  var commonHideQuestionsLogicMatches = logics.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.QUESTIONS && logic.action === LogicAction.HIDE;
  });
  var commonHidePagesLogicMatches = logics.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.PAGES && logic.action === LogicAction.HIDE;
  });
  var pushedQuestions = 0;
  bundles.forEach(function (bundle) {
    bundle.questions.forEach(function (questionActor) {
      var question = questionActor.getSnapshot().context;

      if (commonHidePagesLogicMatches.length > 0 && commonHidePagesLogicMatches.some(function (l) {
        return l.toPages.includes(question.page);
      })) {
        return;
      }

      if (commonHideQuestionsLogicMatches.length > 0 && commonHideQuestionsLogicMatches.some(function (l) {
        return l.toQuestions.includes(question.id);
      })) {
        return;
      }

      if (commonPagesLogicMatches.length > 0 && !commonPagesLogicMatches.some(function (l) {
        return l.toPages.includes(question.page);
      })) {
        return;
      }

      if (commonQuestionsLogicMatches.length > 0 && !commonQuestionsLogicMatches.some(function (l) {
        return l.toQuestions.includes(question.id);
      })) {
        return;
      }

      pushedQuestions++;
    });
  });
  return pushedQuestions === 0;
};

export var filterBundlesQuestionsByLogic = function filterBundlesQuestionsByLogic(bundles, globalLogic, queryParamsRaw, linkParamsRaw) {
  var _bundles$, _bundles$$questions, _root$quotaRef;

  var queryParams = queryParamsRaw || {};
  var linkParams = linkParamsRaw || {};
  var reversedQpKey = Object.keys(linkParams).reduce(function (acc, item) {
    acc[linkParams[item]] = item;
    return acc;
  }, {});
  var matchedLinkParams = Object.keys(queryParams).reduce(function (acc, qpKey) {
    if (reversedQpKey[qpKey]) {
      acc[reversedQpKey[qpKey]] = queryParams[qpKey];
    }

    return acc;
  }, {});
  var matchedGlobalLogic = globalLogic.filter(function (logic) {
    var _matchedLinkParams$lo, _logic$linkValue;

    return ((_matchedLinkParams$lo = matchedLinkParams[logic.linkId]) === null || _matchedLinkParams$lo === void 0 ? void 0 : _matchedLinkParams$lo.toLowerCase()) === ((_logic$linkValue = logic.linkValue) === null || _logic$linkValue === void 0 ? void 0 : _logic$linkValue.toLowerCase());
  });
  var isAllQuestionsHideByCommonLogic = checkIsAllQuestionsHideByCommonLogic(bundles, matchedGlobalLogic);

  if (isAllQuestionsHideByCommonLogic) {
    matchedGlobalLogic = [];
  }

  var execLogic;
  var questionsLogicMatches = [];
  var pagesLogicMatches = [];
  var hideQuestionsLogicMatches = [];
  var hidePagesLogicMatches = [];
  var commonQuestionsLogicMatches = matchedGlobalLogic.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.QUESTIONS && logic.action === LogicAction.SHOW;
  });
  var commonPagesLogicMatches = matchedGlobalLogic.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.PAGES && logic.action === LogicAction.SHOW;
  });
  var commonHideQuestionsLogicMatches = matchedGlobalLogic.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.QUESTIONS && logic.action === LogicAction.HIDE;
  });
  var commonHidePagesLogicMatches = matchedGlobalLogic.filter(function (logic) {
    return logic.type === LogicType.LINK_PARAM && logic.transition === LogicTransition.PAGES && logic.action === LogicAction.HIDE;
  });
  adjustQuestions(bundles, false);
  if (!(bundles !== null && bundles !== void 0 && (_bundles$ = bundles[0]) !== null && _bundles$ !== void 0 && (_bundles$$questions = _bundles$.questions) !== null && _bundles$$questions !== void 0 && _bundles$$questions[0])) return [];

  var root = bundles[0].questions[0]._parent.getSnapshot().context;

  var quotaByQuestionId = ((_root$quotaRef = root.quotaRef) === null || _root$quotaRef === void 0 ? void 0 : _root$quotaRef.getSnapshot().context.quotaStatusByQuestionId) || {};
  var newBundles = bundles.reduce(function (acc, bundle) {
    var _execLogic;

    switch ((_execLogic = execLogic) === null || _execLogic === void 0 ? void 0 : _execLogic.transition) {
      case LogicTransition.PAGE:
        if (execLogic.toPage !== bundle.page.id) {
          return acc;
        }

        execLogic = null;
        break;

      case LogicTransition.DISQUAL:
      case LogicTransition.PERSONAL_COMPLETE:
      case LogicTransition.REGULAR_COMPLETE:
        return acc;
    }

    bundle.questions = bundle.questions.reduce(function (questions, question) {
      var _getQ = getQ(question),
          answersActors = _getQ.answersActors,
          groupsActors = _getQ.groupsActors,
          id = _getQ.id,
          logics = _getQ.logics,
          responseActor = _getQ.responseActor,
          type = _getQ.type,
          page = _getQ.page;

      if (commonPagesLogicMatches.length > 0 && commonPagesLogicMatches.every(function (logic) {
        return !logic.toPages.includes(page);
      })) {
        return questions;
      }

      if (commonHidePagesLogicMatches.length > 0 && commonHidePagesLogicMatches.some(function (logic) {
        return logic.toPages.includes(page);
      })) {
        return questions;
      }

      if (commonQuestionsLogicMatches.length > 0 && commonQuestionsLogicMatches.every(function (logic) {
        return !logic.toQuestions.includes(id);
      })) {
        return questions;
      }

      if (commonHideQuestionsLogicMatches.length > 0 && commonHideQuestionsLogicMatches.some(function (logic) {
        return logic.toQuestions.includes(id);
      })) {
        return questions;
      }

      if (pagesLogicMatches.length > 0 && pagesLogicMatches.some(function (pagesLogic) {
        return !pagesLogic.toPages.includes(bundle.page.id);
      })) {
        return questions;
      }

      if (questionsLogicMatches.length > 0 && questionsLogicMatches.some(function (questionsLogic) {
        return !questionsLogic.toQuestions.includes(id);
      })) {
        return questions;
      }

      if (hidePagesLogicMatches.length > 0 && hidePagesLogicMatches.some(function (pagesLogic) {
        return pagesLogic.toPages.includes(bundle.page.id);
      })) {
        return questions;
      }

      if (hideQuestionsLogicMatches.length > 0 && hideQuestionsLogicMatches.some(function (questionsLogic) {
        return questionsLogic.toQuestions.includes(id);
      })) {
        return questions;
      }

      if (execLogic) {
        switch (execLogic.transition) {
          case LogicTransition.DISQUAL:
          case LogicTransition.PERSONAL_COMPLETE:
          case LogicTransition.REGULAR_COMPLETE:
            return questions;

          case LogicTransition.PAGE:
            if (execLogic.toPage !== bundle.page.id) {
              return questions;
            }

            execLogic = null;
            break;

          case LogicTransition.QUESTION:
            if (execLogic.toQuestion !== id) {
              return questions;
            }

            execLogic = null;
            break;

          case LogicTransition.QUESTIONS:
            questionsLogicMatches.push(execLogic);

            if (!execLogic.toQuestions.includes(id)) {
              return questions;
            }

            execLogic = null;
            break;

          case LogicTransition.PAGES:
            pagesLogicMatches.push(execLogic);

            if (!execLogic.toPages.includes(bundle.page.id)) {
              return questions;
            }

            execLogic = null;
            break;
        }
      }

      if (!bundle.random) {
        var sortedLogics = logics.slice().sort(function (a, b) {
          var isAOneOf = [LogicTransition.QUESTIONS, LogicTransition.PAGES].includes(a.transition);
          var isBOneOf = [LogicTransition.QUESTIONS, LogicTransition.PAGES].includes(b.transition);

          if (isAOneOf && isBOneOf) {
            return 0;
          }

          if (isAOneOf) {
            return 1;
          }

          if (isBOneOf) {
            return -1;
          }

          return 0;
        });
        var matchedLogics = sortedLogics.filter(function (logic) {
          return logicMatch(logic, groupsActors.map(function (g) {
            return g.getSnapshot().context;
          }), answersActors.map(function (a) {
            return a.getSnapshot().context;
          }), responseActor.getSnapshot().context, getQ(question), quotaByQuestionId);
        }).concat(matchedGlobalLogic);
        var matchedPagesLogics = matchedLogics.filter(function (l) {
          return l.transition === LogicTransition.PAGES && l.action !== LogicAction.HIDE;
        });
        var matchedQuestionsLogics = matchedLogics.filter(function (l) {
          return l.transition === LogicTransition.QUESTIONS && l.action !== LogicAction.HIDE;
        });
        var hideMatchedPagesLogics = matchedLogics.filter(function (l) {
          return l.transition === LogicTransition.PAGES && l.action === LogicAction.HIDE;
        });
        var hideMatchedQuestionsLogics = matchedLogics.filter(function (l) {
          return l.transition === LogicTransition.QUESTIONS && l.action === LogicAction.HIDE;
        });

        if (matchedPagesLogics.length || matchedQuestionsLogics.length || hideMatchedPagesLogics.length || hideMatchedQuestionsLogics.length) {
          if (matchedPagesLogics.length || matchedQuestionsLogics.length) {
            execLogic = null;

            if (matchedPagesLogics.length) {
              var lastPagesLogic = JSON.parse(JSON.stringify(matchedPagesLogics[matchedPagesLogics.length - 1]));
              var commonToPages = matchedPagesLogics.slice(0, -1).reduce(function (acc, item) {
                return acc.concat(item.toPages);
              }, []);
              commonToPages.map(function (el) {
                return lastPagesLogic.toPages.push(el);
              });
              pagesLogicMatches.push(lastPagesLogic);
            }

            if (matchedQuestionsLogics.length) {
              var lastQuestionsLogic = JSON.parse(JSON.stringify(matchedQuestionsLogics[matchedQuestionsLogics.length - 1]));
              var commonToQuestions = matchedQuestionsLogics.slice(0, -1).reduce(function (acc, item) {
                return acc.concat(item.toQuestions);
              }, []);
              commonToQuestions.map(function (el) {
                return lastQuestionsLogic.toQuestions.push(el);
              });
              questionsLogicMatches.push(lastQuestionsLogic);
            }
          }

          if (hideMatchedPagesLogics.length || hideMatchedQuestionsLogics.length) {
            execLogic = null;

            if (hideMatchedPagesLogics.length) {
              var _lastPagesLogic = JSON.parse(JSON.stringify(hideMatchedPagesLogics[hideMatchedPagesLogics.length - 1]));

              var _commonToPages = hideMatchedPagesLogics.slice(0, -1).reduce(function (acc, item) {
                return acc.concat(item.toPages);
              }, []);

              _commonToPages.map(function (el) {
                return _lastPagesLogic.toPages.push(el);
              });

              hidePagesLogicMatches.push(_lastPagesLogic);
            }

            if (hideMatchedQuestionsLogics.length) {
              var _lastQuestionsLogic = JSON.parse(JSON.stringify(hideMatchedQuestionsLogics[hideMatchedQuestionsLogics.length - 1]));

              var _commonToQuestions = hideMatchedQuestionsLogics.slice(0, -1).reduce(function (acc, item) {
                return acc.concat(item.toQuestions);
              }, []);

              _commonToQuestions.map(function (el) {
                return _lastQuestionsLogic.toQuestions.push(el);
              });

              hideQuestionsLogicMatches.push(_lastQuestionsLogic);
            }
          }
        } else {
          var _iterator = _createForOfIteratorHelper(sortedLogics),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var logic = _step.value;

              /*
              TODO disabled, take last to satisfy compliance with legacy system
              if (execLogic) break; // already found
              */
              if (logicMatch(logic, groupsActors.map(function (g) {
                return g.getSnapshot().context;
              }), answersActors.map(function (a) {
                return a.getSnapshot().context;
              }), responseActor.getSnapshot().context, getQ(question), quotaByQuestionId)) {
                execLogic = logic;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      }

      questions.push(question);
      return questions;
    }, []);

    if (bundle.questions.length > 0) {
      acc.push(bundle);
    }

    return acc;
  }, []);
  adjustQuestions(newBundles, true);
  var lastBundle = execLogic && createLastBundle(execLogic);

  if (!lastBundle && questionsLogicMatches.length > 0 || pagesLogicMatches.length > 0) {
    var lastMatch = pagesLogicMatches[pagesLogicMatches.length - 1] || questionsLogicMatches[questionsLogicMatches.length - 1];
    lastBundle = lastMatch && createLastBundle(lastMatch);
  }

  if (lastBundle) {
    newBundles.push(lastBundle);
  }

  return newBundles;
};