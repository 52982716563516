import { replaceMask } from '@webapp/common/lib/ui';
import { getApiClass } from '@webapp/survey-new/src/api/queries-api';
export var replaceMaxSize = function replaceMaxSize(s, v) {
  return replaceMask({
    max: v
  }, s);
};
export var replaceFileExt = function replaceFileExt(s, v) {
  return replaceMask({
    formats: v
  }, s);
};
export var replaceMaxCount = function replaceMaxCount(s, v) {
  return replaceMask({
    count: v
  }, s);
};
export var mapQuestionForSnapshot = function mapQuestionForSnapshot(actor) {
  var _actor$getSnapshot$co = actor.getSnapshot().context,
      answersActors = _actor$getSnapshot$co.answersActors,
      dirty = _actor$getSnapshot$co.dirty,
      expired = _actor$getSnapshot$co.expired,
      groupsActors = _actor$getSnapshot$co.groupsActors,
      id = _actor$getSnapshot$co.id,
      responseActor = _actor$getSnapshot$co.responseActor;
  return {
    id: id,
    answersActors: answersActors,
    groupsActors: groupsActors,
    responseActor: responseActor,
    expired: expired,
    dirty: dirty
  };
};
export var mapQuestionForQueue = function mapQuestionForQueue(q) {
  var api = getApiClass(q.getSnapshot().context.type);
  return api.query(q);
};