import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createActorContext, useSelector } from '@xstate/react';
import { useCallback, useEffect, useState } from 'react';
import { assign, setup } from 'xstate';
import { isDev } from '@webapp/common/lib/const';
import { surveyMachine as _surveyMachine } from '@webapp/survey-new/src/machine/machine';
import { assertEventType } from "./machine/lib";
export var appMachine = setup({
  types: {
    context: {},
    events: {},
    input: {}
  }
}).createMachine({
  id: 'appMachine',
  initial: 'idle',
  context: function context(_ref) {
    var input = _ref.input;
    return {
      surveyMachine: null,
      devTools: input.devTools || false,
      offlineSyncInProgress: false
    };
  },
  states: {
    idle: {
      entry: [assign({
        surveyMachine: function surveyMachine(_ref2) {
          var spawn = _ref2.spawn;
          return spawn(_surveyMachine, {
            systemId: 'surveyMachine'
          });
        }
      })],
      on: {
        INIT: {
          actions: [function (_ref3) {
            var context = _ref3.context,
                event = _ref3.event;
            context.surveyMachine.send(_objectSpread(_objectSpread({}, event), {}, {
              type: 'BOOTSTRAP'
            }));
          }]
        }
      }
    },
    loaded: {}
  },
  on: {
    TOGGLE_DEV: {
      actions: assign(function (_ref4) {
        var event = _ref4.event;
        assertEventType(event, 'TOGGLE_DEV');
        return {
          devTools: event.enabled
        };
      })
    }
  }
});
export var AppMachineContext = createActorContext(appMachine, {
  input: {
    devTools: isDev
  }
});
export var useStore = function useStore() {
  var state = AppMachineContext.useSelector(function (s) {
    return s;
  });

  var _sendApp = AppMachineContext.useActorRef();

  var surveyMachine1 = state.context.surveyMachine;
  var surveyMachine1Default = useSelector(surveyMachine1, function (state) {
    return state.value;
  });

  var _useState = useState(surveyMachine1Default),
      surveyState = _useState[0],
      setSurveyState = _useState[1];

  var saveIncomplete = useCallback(function (sourceQuestion) {
    surveyMachine1.send({
      type: 'SAVE_INCOMPLETE',
      sourceQuestion: sourceQuestion
    });
  }, [surveyMachine1]);
  useEffect(function () {
    var subscription = surveyMachine1.subscribe(function (state) {
      setSurveyState(state.value);
    });
    return function () {
      return subscription.unsubscribe();
    };
  }, [surveyMachine1]);
  return {
    saveIncomplete: saveIncomplete,
    devTools: {
      enabled: true
    },
    state: surveyState,
    send: surveyMachine1.send,
    useSelector: AppMachineContext.useSelector,
    offlineSyncInProgress: state.context.offlineSyncInProgress,
    sendApp: function sendApp(event) {
      return _sendApp.send(event);
    }
  };
};