import type { ActorRef, ActorRefFrom, Snapshot } from 'xstate';
import { assign, sendTo, setup } from 'xstate';

import type { QuestionResponseActor, ResponseEventParams } from '@webapp/survey-new/src/entities/response-machine';
import { QuestionResponseMachine } from '@webapp/survey-new/src/entities/response-machine';
import type { SaveQuestionGroup } from '@webapp/survey-new/src/machine/lib';

type GroupEvents =
    | { type: 'INIT' }
    | { type: 'CHANGE_RESPONSE_VALUE'; value: PrimitiveValue; answerId: number }
    | { type: 'CHANGE_RESPONSE_EXTRA'; value: string; answerId: number }
    | (ResponseEventParams & {
          type: 'RESPONSE_CHANGE';
      })
    | (ResponseEventParams & {
          type: 'RESPONSE_CHANGE_COMMENT';
      })
    | (ResponseEventParams & {
          type: 'RESPONSE_CHANGE_EXTRA';
      });
export type GroupContext = {
    questionId: number;
    id: number;
    name: string | null;
    type: number | null;
    numericValue: number | null;
    exception: boolean;
    answers: Array<string>;
    responses: Record<string, QuestionResponseActor>;
    parentRef: ParentActor;
    responsesValues: Array<string | number | boolean>;
};

type GroupInput = {
    questionId: number;
    id: number;
    name: string | null;
    type: number | null;
    numericValue: number | null;
    exception: boolean;
    answers: Array<string>;
    answerIds: Array<number>;
    parentRef: ParentActor;
    savedResponse: SaveQuestionGroup;
};

enum GroupState {
    INITIAL = 'inital'
}

type ChildEvents =
    | ({ type: 'RESPONSE_CHANGE' } & ResponseEventParams)
    | ({ type: 'RESPONSE_CHANGE_EXTRA' } & ResponseEventParams)
    | ({ type: 'GROUP_RESPONSE_CHANGE' } & ResponseEventParams);

type ParentActor = ActorRef<Snapshot<unknown>, ChildEvents>;

export const GroupMachineDefinition = setup({
    types: {
        context: {} as GroupContext,
        input: {} as GroupInput,
        events: {} as GroupEvents
    }
}).createMachine({
    id: 'QuestionGroupMachine',
    initial: GroupState.INITIAL,
    context: ({ input, spawn, self }) => {
        const responses: Record<string, QuestionResponseActor> = {};
        input.answerIds.forEach((answerId) => {
            responses[answerId] = spawn(QuestionResponseMachine, {
                input: {
                    questionId: input.questionId,
                    groupId: input.id,
                    parentRef: self,
                    savedResponse: input.savedResponse?.responses?.[answerId]
                }
            });
        });
        return { ...input, responses, responsesValues: [] };
    },
    states: {
        [GroupState.INITIAL]: {
            on: {
                CHANGE_RESPONSE_VALUE: {
                    actions: [
                        ({ event, context }) => {
                            context.responses[event.answerId].send({
                                type: 'CHANGE',
                                value: event.value,
                                questionId: context.questionId
                            });
                        },
                        sendTo(
                            ({ context: { parentRef } }) => parentRef,
                            ({ context: { questionId, id }, event: { value, answerId } }) => ({
                                type: 'RESPONSE_CHANGE',
                                questionId,
                                value,
                                groupId: id,
                                answerId
                            })
                        ),
                        sendTo(
                            ({ context: { parentRef } }) => parentRef,
                            ({ context: { questionId, id }, event: { value, answerId } }) => ({
                                type: 'GROUP_RESPONSE_CHANGE',
                                questionId,
                                value,
                                groupId: id,
                                answerId
                            })
                        )
                    ]
                },
                CHANGE_RESPONSE_EXTRA: {
                    actions: [
                        ({ event, context }) => {
                            context.responses[event.answerId].send({
                                type: 'CHANGE_EXTRA',
                                value: event.value
                            });
                        },
                        sendTo(
                            ({ context: { parentRef } }) => parentRef,
                            ({ context: { questionId }, event: { value } }) => ({
                                type: 'RESPONSE_CHANGE_EXTRA',
                                questionId,
                                value
                            })
                        )
                    ]
                }
            }
        }
    },
    on: {
        RESPONSE_CHANGE: {
            actions: assign({
                responsesValues: ({ context }) =>
                    Object.values(context.responses).map((resp) => resp.getSnapshot().context.value)
            })
        }
    }
});

export type GroupMachineActor = ActorRefFrom<typeof GroupMachineDefinition>;
