import { QuestionType } from '@webapp/common/resources/constants';
import { getQ } from '@webapp/survey-new/src/machine/lib';
export var calcSurveyProgress = function calcSurveyProgress(questions) {
  var eligibleQuestions = questions.filter(function (q) {
    return getQ(q).type !== QuestionType.TEXT_BLOCK;
  });
  var changedQuestions = eligibleQuestions.filter(function (q) {
    return getQ(q).dirty;
  });
  return changedQuestions.length / eligibleQuestions.length;
};