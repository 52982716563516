import dayjs, { duration } from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import { uniqBy } from 'lodash';
import type { NestedItem } from 'resources/questions';
import { assign, enqueueActions, fromCallback, fromPromise, sendParent, sendTo, setup } from 'xstate';
import type { Actor, ActorRef, ActorRefFrom, Snapshot } from 'xstate';

import { humanMaxUserFileSize, isBrowser, MAX_USER_FILE_SIZE } from '@webapp/common/lib/const';
import { partsToSeconds } from '@webapp/common/lib/date';
import { hasExtension, shuffleArray } from '@webapp/common/lib/utils';
import { getApiClass } from '@webapp/survey-new/src/api/queries-api';
import type { QuestionAnswerActor, QuestionAnswerEntity } from '@webapp/survey-new/src/entities/answer-machine';
import { QuestionAnswerMachineDefinition } from '@webapp/survey-new/src/entities/answer-machine';
import type { QuestionGroupEntity } from '@webapp/survey-new/src/entities/question-group';
import type { QuestionResponseActor, ResponseEventParams } from '@webapp/survey-new/src/entities/response-machine';
import { QuestionResponseMachine } from '@webapp/survey-new/src/entities/response-machine';
import { surveyApi } from '@webapp/survey-new/src/machine/api';
import { replaceFileExt, replaceMaxCount, replaceMaxSize } from '@webapp/survey-new/src/machine/helpers/question';
import type { SavedQuestion } from '@webapp/survey-new/src/machine/lib';
import { loadTimer } from '@webapp/survey-new/src/machine/lib';
import type { TimerMachineActor, TimerStopReason } from '@webapp/survey-new/src/machine/timer-machine';
import { TimerMachine } from '@webapp/survey-new/src/machine/timer-machine';

import { fileSize } from '../../lib/ui';
import { AnswerType, QuestionsWithPostSort, QuestionType, withClickReply } from '../constants';
import type { ResidenceModel } from '../entities';

import type { GroupMachineActor } from './group-machine';
import { GroupMachineDefinition } from './group-machine';
import { withSelfAnswer } from './question';
import type { createQuestionRes } from './question';

type SavedTimer = {
    minutes: number;
    hours: number;
    seconds: number;
};

const loadedResidences = Object.create(null);

export const loadResidencesItems = async (
    { questionId, level, parent }: { questionId: number; level: number; parent: number },
    actor: ResidenceActorRef
) => {
    const key = `${questionId}_${level}_${parent}`;
    if (loadedResidences[key]) return [];

    let residences: Array<ResidenceModel> = [];

    try {
        residences = await surveyApi.getResidences(level, parent);
    } catch (e) {
        return [];
    }

    loadedResidences[key] = true;
    actor.send({ type: 'ADD_RESIDENCE', residences });
};

type ResidenceEvent =
    | { type: 'LOAD_RESIDENCE'; level: number; parent: number }
    | { type: 'ADD_RESIDENCE'; residences: Array<ResidenceModel> };
enum ResidenceState {
    INITIAL = 'inintial',
    LOADING = 'loading',
    SUCCESS_LOADED = 'success_loaded',
    FAILED_LOADED = 'failed_loaded'
}

const ResidenceMachineDefinition = setup({
    types: {
        context: {} as {
            questionId: number;
            residences: Array<ResidenceModel>;
        },
        events: {} as ResidenceEvent,
        input: {} as { questionId: number }
    }
}).createMachine({
    id: 'ResidenceMachine',
    initial: ResidenceState.INITIAL,
    context: ({ input: { questionId } }) => ({
        residences: [],
        questionId
    }),
    states: {
        [ResidenceState.INITIAL]: {}
    },
    on: {
        ADD_RESIDENCE: {
            actions: assign(({ context, event }) => {
                return {
                    residences: context.residences.concat(event.residences)
                };
            })
        }
    }
});

type ResidenceActorRef = ActorRefFrom<typeof ResidenceMachineDefinition>;

const loadedNested = Object.create(null);

export const loadNestedItems = (
    { questionId, level, parent }: { questionId: number; level: number; parent: number | null },
    actor: NestedActorRef
) => {
    const key = `${questionId}_${level}_${parent}`;

    if (loadedNested[key]) {
        actor.send({ type: 'ADD_NESTED', items: { [level]: loadedNested[key] } });
        return;
    }

    let nested: Array<NestedItem> = [];

    surveyApi.getNested(questionId, level, parent).then((res) => {
        ({ items: nested } = res);

        loadedNested[key] = nested;
        actor.send({ type: 'ADD_NESTED', items: { [level]: nested } });
    });
};

type NestedEvent =
    | { type: 'LOAD_NESTED'; level: number; parent: number | null }
    | { type: 'ADD_NESTED'; items: Record<string, Array<NestedItem>> }
    | { type: 'DELETE_NESTED'; item: string | number };

enum NestedState {
    INITIAL = 'inintial',
    LOADING = 'loading',
    SUCCESS_LOADED = 'success_loaded',
    FAILED_LOADED = 'failed_loaded'
}

const NestedMachineDefinition = setup({
    types: {
        context: {} as {
            nested: Record<number, Array<NestedItem>>;
            questionId: number;
        },
        events: {} as NestedEvent,
        input: {} as { questionId: number }
    }
}).createMachine({
    id: 'NestedMachine',
    initial: NestedState.INITIAL,
    context: ({ input: { questionId } }) => ({
        nested: [],
        questionId
    }),
    states: {
        [NestedState.INITIAL]: {}
    },
    on: {
        ADD_NESTED: {
            reenter: true,
            actions: assign(({ event, context }) => {
                return {
                    nested: {
                        ...context.nested,
                        ...event.items
                    }
                };
            })
        },
        DELETE_NESTED: {
            reenter: true,
            actions: assign(({ event, context }) => {
                const newNested = { ...context.nested };
                delete newNested[event.item];

                return {
                    nested: newNested
                };
            })
        }
    }
});

type NestedActorRef = ActorRefFrom<typeof NestedMachineDefinition>;

/**
 const key = `${questionId}_${level}_${parentId}`;

        if (loadedNested[key]) return;

        let items = [];

        try {
            ({ items } = yield surveyApi.getNested(questionId, level, parentId));
        } catch (e) {
            return;
        }

        loadedNested[key] = true;

        const q = self.questions.find((q) => q.id === questionId);

        q.items.set(level, items);
 */
type UploadedFile = {
    id: number;
    type: string | null;
    file: {
        id: number;
        name: string;
        path: string;
        size: number;
    };
};

export type QuestionEvent =
    | { type: 'SHOW'; readOnly?: boolean }
    | { type: 'HIDE' }
    | { type: 'TIMER_EXPIRED'; reason: TimerStopReason }
    | { type: 'VALIDATE' }
    | { type: 'TOUCH' }
    | { type: 'STOP_TIMER' }
    | { type: 'LOAD_RESIDENCE'; level: number; id: number }
    | { type: 'FILE_UPLOAD'; files: Array<File> }
    | { type: 'FILE_DELETE'; id: number }
    | ({ type: 'QUESTION_RESPONSE_CHANGE' } & ResponseEventParams)
    | { type: 'QUESTION_CHANGE_EXTRA'; value: string }
    | ({ type: 'QUESTION_RESPONSE_CHANGE_COMMENT' } & ResponseEventParams)
    | ({ type: 'QUESTION_RESPONSE_CHANGE_EXTRA' } & ResponseEventParams)
    | { type: 'QUESTION_TOUCH' }
    | { type: 'SET_TEST_RESULT'; currentPoints: number; maxPoints: number; isChecked: boolean }
    | { type: 'QUESTION_VALIDATE' }
    | { type: 'QUESTION_SAVE_INCOMPLETE' }
    | ({
          type: 'RESPONSE_CHANGE';
      } & ResponseEventParams)
    | ({
          type: 'GROUP_RESPONSE_CHANGE';
      } & ResponseEventParams)
    | ({
          type: 'RESPONSE_CHANGE_EXTRA';
      } & ResponseEventParams)
    | ({ type: 'RESPONSE_CHANGE_COMMENT' } & ResponseEventParams)
    | { type: 'TIMER_TICK' }
    | { type: 'SET_COMMENT_INVALID'; value: boolean }
    | { type: 'VALIDATE_FILES'; files: Array<File> }
    | { type: 'SET_SHOW_BY_LOGIC'; value: boolean }
    | { type: 'SET_ORDER'; value: number }
    | { type: 'SET_DETAILS_LOADED' };

export enum QuestionState {
    idle = 'idle',
    visible = 'visible',
    timer = 'timer',
    answered = 'answered',
    sent = 'sent',
    file_uploading = 'file_uploading',
    file_deleting = 'file_deleting'
}

export interface QuestionContext extends Omit<createQuestionRes, 'questionId'> {
    responseActor?: QuestionResponseActor;
    answersRaw?: Array<QuestionAnswerEntity>;
    // FIXME: тут должно быть rawAnswers
    answersActors?: Array<QuestionAnswerActor>;
    groups?: Array<QuestionGroupEntity>;
    groupsActors?: Array<GroupMachineActor>;
    timerLeft?: Duration;
    clickReply?: boolean;
    timer?: TimerMachineActor;
    residence?: Array<any>;
    residenceActor?: ResidenceActorRef;
    nestedActor?: NestedActorRef;
    files?: Array<UploadedFile>;
    parentRef?: ParentActor;
    showByLogic?: boolean;
    savedAnswer?: SavedQuestion;
}

export interface QuestionInput {
    answersRaw: Array<QuestionAnswerEntity>;
}

const storageTimerKey = (id: number): `question_${string}_timer` => `question_${id}_timer`;

const postSortAnswers = (answers: Array<QuestionAnswerActor>): Array<QuestionAnswerActor> =>
    answers.sort((aActor, bActor) => {
        const a = aActor.getSnapshot().context;
        const b = bActor.getSnapshot().context;
        if ((a.type === AnswerType.SELF || a.exception) && b.type !== AnswerType.SELF && !b.exception) return 1;
        if (a.type !== AnswerType.SELF && !a.exception && (b.type === AnswerType.SELF || b.exception)) return -1;
        return 0;
    });

// https://dev.to/davidkpiano/release-notes-xstate-4-22-0-xstate-react-1-5-0-3d3k
// https://codesandbox.io/s/keen-grass-qiuz6?file=/src/index.tsx:0-9

type ChildEvents =
    | { type: 'QUESTION_TOUCHED'; questionId: number }
    | { type: 'SELECT_NEXT_BUNDLE'; noValidate: boolean }
    | { type: 'SET_DETAILS_LOADED' };

type ParentActor = ActorRef<Snapshot<unknown>, ChildEvents>;

export const QuestionMachineDefinition = setup({
    types: {
        context: {} as QuestionContext,
        events: {} as QuestionEvent,
        input: {} as createQuestionRes & { parentRef: ParentActor; savedAnswer?: SavedQuestion }
    },
    actors: {
        uploadFile: fromPromise<Array<UploadedFile>, { context: QuestionContext; files: Array<File> }>(
            async ({ input: { files, context } }) => {
                let newFiles = [];

                newFiles = await surveyApi.uploadFiles(context.id, files);

                return newFiles as Array<UploadedFile>;
            }
        ),
        deleteFile: fromPromise<{ id: number }, { context: QuestionContext; id: number }>(async ({ input: { id } }) => {
            await surveyApi.deleteFile(id);
            return { id };
        })
    }
}).createMachine({
    id: 'QuestionMachine',
    initial: QuestionState.idle,
    context: ({ input: { answers, parentRef, ...inputContext } }) => ({
        ...inputContext,
        dirty: Boolean(inputContext.savedAnswer),
        parentRef,
        answersRaw: answers
    }),
    entry: [
        assign({
            responseActor: ({ context, spawn, self }) => {
                return spawn(QuestionResponseMachine, {
                    systemId: `question-${context.id}-response`,
                    input: {
                        questionId: context.id,
                        parentRef: self,
                        savedResponse: context.savedAnswer?.response
                    }
                });
            },
            answersActors: ({ context, spawn, self }) => {
                return context.answersRaw.map((a) =>
                    spawn(QuestionAnswerMachineDefinition, {
                        systemId: `question-${context.id}-answer-${a.id}`,
                        input: {
                            ...a,
                            questionId: context.id,
                            questionType: context.type,
                            id: a.id,
                            parentRef: self,
                            savedAnswer: context.savedAnswer?.answers?.find((savedA) => savedA.id === a.id)
                        }
                    })
                );
            },
            residenceActor: ({ context, spawn }) => {
                if (context.type !== QuestionType.RESIDENCE) {
                    return undefined;
                }
                return spawn(ResidenceMachineDefinition, {
                    input: {
                        questionId: context.id
                    }
                });
            },
            nestedActor: ({ context, spawn }) => {
                if (context.type !== QuestionType.NESTED_LIST) {
                    return undefined;
                }

                return spawn(NestedMachineDefinition, {
                    input: {
                        questionId: context.id
                    }
                });
            },
            groupsActors: ({ context, spawn, self }) => {
                return context.groups.map((group) =>
                    spawn(GroupMachineDefinition, {
                        input: {
                            ...group,
                            questionId: context.id,
                            answerIds: context.answersRaw.map((a) => a.id),
                            parentRef: self,
                            savedResponse: context.savedAnswer?.groups?.find((savedG) => savedG.id === group.id)
                        }
                    })
                );
            }
        })
    ],
    states: {
        [QuestionState.idle]: {
            on: {
                SHOW: {
                    target: QuestionState.visible
                }
            }
        },
        [QuestionState.visible]: {
            entry: enqueueActions(({ enqueue, context, event, ...props }) => {
                if (context.params.randomOrder) {
                    enqueue.assign({
                        answersActors: ({ context }) => {
                            if (QuestionsWithPostSort.has(context.type)) {
                                return postSortAnswers(shuffleArray(context.answersActors));
                            }
                            return shuffleArray(context.answersActors);
                        }
                    });
                }
                if (context.params.timer) {
                    if (!isBrowser) {
                        // TODO ?? purge
                        console.error('!!!!!!!!!!!!!!!!');
                        return;
                    }
                    // let hours, minutes, seconds;
                    // try {
                    //     ({ hours, minutes, seconds } = LocalStorage.get<SavedTimer>(storageTimerKey(ctx.id)));
                    // } catch (e) {
                    //     ({ hours, minutes, seconds } = ctx.params);
                    // }
                    // ({ hours, minutes, seconds } = ctx.params);
                    const isReadOnly = event.type === 'SHOW' && event.readOnly;
                    const isSavedTimer = loadTimer(`question-timer-${context.id}`);
                    if (isReadOnly ? isSavedTimer : !context.timer) {
                        enqueue.assign({
                            timer: ({ context, spawn, self }) =>
                                spawn(TimerMachine, {
                                    systemId: `question-timer-${context.id}`,
                                    input: {
                                        duration: partsToSeconds(
                                            context.params.hours,
                                            context.params.minutes,
                                            context.params.seconds
                                        ),
                                        uniqueId: `question-timer-${context.id}`,
                                        parentRef: self
                                    }
                                })
                        });
                    }
                }
            }),
            on: {
                SHOW: {
                    actions: ({ context }) => {
                        if (context.timer) {
                            context.timer.send({ type: 'START' });
                        }
                    }
                },
                QUESTION_CHANGE_EXTRA: {
                    actions: ({ context, event: { value } }) => {
                        context.response.send({ type: 'CHANGE_EXTRA', value });
                    }
                },
                QUESTION_RESPONSE_CHANGE: {
                    actions: [
                        enqueueActions(({ context, event, enqueue }) => {
                            const api = getApiClass(context.type);

                            api.change(context, event);
                            enqueue.assign({
                                dirty: true
                            });
                            if (context.invalid) {
                                enqueue.raise({ type: 'VALIDATE' });
                            }
                        }),
                        sendTo(
                            ({ context }) => context.parentRef,
                            ({ context }) => {
                                return {
                                    type: 'QUESTION_TOUCHED',
                                    questionId: context.id
                                };
                            }
                        ),
                        enqueueActions(({ enqueue }) => enqueue.raise({ type: 'TOUCH' })),
                        enqueueActions(({ enqueue, context }) => {
                            const replyByClick =
                                context.clickReply && withClickReply(context) && !withSelfAnswer(context.answersActors);
                            if (replyByClick) {
                                enqueue.raise({ type: 'VALIDATE' });
                                if (!context.invalid && !context.params.comment) {
                                    enqueue.sendTo(({ context }) => context.parentRef, {
                                        type: 'SELECT_NEXT_BUNDLE',
                                        noValidate: false
                                    });
                                }
                            }
                        })
                    ]
                },
                QUESTION_RESPONSE_CHANGE_EXTRA: {
                    actions: enqueueActions(({ enqueue, context }) => {
                        if (context.invalid) {
                            enqueue.raise({ type: 'VALIDATE' });
                        }
                    })
                },
                TIMER_EXPIRED: {
                    actions: enqueueActions(({ enqueue, context, event: { reason } }) => {
                        const now = dayjs();
                        const timer = context.timer.getSnapshot().context;
                        const allTime = now.add(timer.duration, 's');

                        const endTime = now.add(timer.duration * 1000 - timer.timeAgo, 'ms');

                        const factTime = dayjs.duration(allTime.diff(endTime));
                        const targetTime = dayjs.duration(allTime.diff(now));
                        enqueue.assign({
                            factTime,
                            targetTime
                        });
                        if (reason === 'expired') {
                            enqueue.sendTo(({ context }) => context.parentRef, {
                                type: 'SELECT_NEXT_BUNDLE',
                                noValidate: true
                            });
                        }
                    })
                },
                RESPONSE_CHANGE_COMMENT: {
                    actions: [
                        enqueueActions(({ context, enqueue }) => {
                            if (context.invalid || context.commentInvalid) {
                                enqueue.raise({ type: 'VALIDATE' });
                            }
                        })
                    ]
                },
                SET_COMMENT_INVALID: {
                    actions: assign(({ event: { value } }) => ({ commentInvalid: value }))
                },
                FILE_UPLOAD: {
                    target: QuestionState.file_uploading
                },
                FILE_DELETE: {
                    target: QuestionState.file_deleting
                }
            }
        },
        [QuestionState.file_uploading]: {
            invoke: {
                id: 'file_uploading',
                src: 'uploadFile',
                input: ({ context, event }) => ({ context, files: event.type === 'FILE_UPLOAD' ? event.files : [] }),
                onDone: {
                    target: QuestionState.visible,
                    actions: [
                        assign(({ context, event }) => {
                            return {
                                files: Array.isArray(context.files) ? context.files.concat(event.output) : event.output,
                                errorText: null
                            };
                        }),
                        ({ context }) => {
                            context.responseActor.send({ type: 'CHANGE', value: '', questionId: context.id });
                        }
                    ]
                },
                onError: {
                    target: QuestionState.visible,
                    actions: assign(({ event }) => {
                        return {
                            errorText: (event.error as any).message
                        };
                    })
                }
            }
        },
        [QuestionState.file_deleting]: {
            invoke: {
                id: 'file_deleting',
                src: 'deleteFile',
                input: ({ context, event }) => ({ context, id: event.type === 'FILE_DELETE' ? event.id : 0 }),
                onDone: {
                    target: QuestionState.visible,
                    actions: [
                        assign(({ context, event }) => {
                            return {
                                files: Array.isArray(context.files)
                                    ? context.files.filter((file) => file.file.id !== event.output.id)
                                    : [],
                                errorText: null
                            };
                        })
                    ]
                },
                onError: {
                    target: QuestionState.visible,
                    actions: assign(({ event }) => {
                        return {
                            errorText: (event.error as any).message
                        };
                    })
                }
            }
        },
        [QuestionState.sent]: {}
    },
    on: {
        SET_TEST_RESULT: {
            actions: assign(({ event: { currentPoints, maxPoints, isChecked } }) => ({
                currentPoints,
                maxPoints,
                isTestCheckedQuestion: isChecked
            }))
        },
        SET_ORDER: {
            actions: assign(({ event }) => {
                return {
                    order: event.value
                };
            })
        },
        SET_SHOW_BY_LOGIC: {
            actions: assign(({ event }) => {
                return {
                    showByLogic: event.value
                };
            })
        },
        RESPONSE_CHANGE: {
            actions: enqueueActions(({ enqueue, context }) => {
                if (context.invalid) {
                    enqueue.raise({ type: 'VALIDATE' });
                }
                enqueue.sendTo(
                    ({ context }) => context.parentRef,
                    ({ context }) => {
                        return {
                            type: 'QUESTION_TOUCHED',
                            questionId: context.id
                        };
                    }
                );
                enqueue.raise({ type: 'TOUCH' });
            })
        },
        GROUP_RESPONSE_CHANGE: {
            actions: [
                ({ context, event }) => {
                    const api = getApiClass(context.type);

                    api.change(context, event);
                }
            ]
        },
        RESPONSE_CHANGE_EXTRA: {
            actions: enqueueActions(({ enqueue, context }) => {
                if (context.invalid) {
                    enqueue.raise({ type: 'VALIDATE' });
                }
            })
        },
        TIMER_EXPIRED: {
            actions: [
                ({ context }) =>
                    sendParent({
                        type: 'TIMER_QUESTION_EXPIRED',
                        questionId: context.id
                    })
            ]
        },
        STOP_TIMER: {
            actions: ({ context }) => {
                context.timer?.send({
                    type: 'STOP'
                });
            }
        },
        LOAD_RESIDENCE: {
            // actions: assign((ctx, evt) => {})
        },
        // FILE_UPLOAD: {
        //     actions: assign(({ context, event }) => {

        //     })
        // },
        FILE_DELETE: {
            // actions: assign((ctx, evt) => {})
        },
        VALIDATE_FILES: {
            actions: enqueueActions(({ enqueue, context, event: { files } }) => {
                const { fileAmount, fileLimitExt } = context.params;
                const {
                    info: {
                        params: {
                            alert: { fileExt, fileMaxAmount, fileMaxSize }
                        }
                    }
                } = (context.parentRef.getSnapshot() as any).context;

                const invalidNameFiles = [...files].filter(({ name }) => !hasExtension(name, fileLimitExt));
                const invalidSizeFiles = [...files].filter(({ size }) => size > MAX_USER_FILE_SIZE);
                const invalidCountMessage =
                    files.length > fileAmount ? replaceMaxCount(fileMaxAmount, fileAmount) : null;

                const invalidNameMessage =
                    invalidSizeFiles.length > 0
                        ? replaceMaxSize(fileMaxSize, humanMaxUserFileSize) +
                          '\n' +
                          invalidSizeFiles.map(({ name, size }) => `${name} - <b>${fileSize(size)}</b>`).join('\n')
                        : '';

                const invalidTypeMessage =
                    invalidNameFiles.length > 0
                        ? replaceFileExt(fileExt, fileLimitExt.map((e) => `<b>${e}</b>`).join(', ')) +
                          '\n' +
                          invalidNameFiles
                              .map(({ name }) => `${name} - <b>${name.split('.').slice(-1)[0].toUpperCase()}</b>`)
                              .join('\n')
                        : '';

                const errorText = [invalidCountMessage, invalidNameMessage, invalidTypeMessage]
                    .filter(Boolean)
                    .join('\n\n');
                const valid = invalidNameFiles.length === 0 && invalidSizeFiles.length === 0 && !invalidCountMessage;
                enqueue.assign({
                    errorText,
                    valid
                });
                if (valid) {
                    enqueue.raise({ type: 'FILE_UPLOAD', files });
                }
            })
        },
        VALIDATE: {
            actions: [
                assign(({ context }) => {
                    const { params, responseActor, type } = context;
                    // ctx.invalid = false;
                    // ctx.commentInvalid = false;
                    let invalid = false;
                    let commentInvalid = false;
                    const api = getApiClass(type);
                    if (
                        type !== QuestionType.CSI &&
                        params.commentRequired &&
                        !responseActor.getSnapshot().context.comment
                    ) {
                        commentInvalid = true;
                    }

                    if (!api.validate(context)) {
                        invalid = true;
                    }

                    return {
                        valid: !invalid && !commentInvalid,
                        invalid,
                        commentInvalid
                    };
                })
                // sendTo(
                //     ({ context }) => context.parentRef,
                //     ({ context }) => {
                //         return {
                //             type: 'QUESTION_TOUCHED',
                //             questionId: context.id
                //         };
                //     }
                // ),
                // enqueueActions(({ enqueue }) => enqueue.raise({ type: 'TOUCH' }))
            ]
        },
        TOUCH: {
            actions: assign({ dirty: true })
        },
        SET_DETAILS_LOADED: {
            actions: enqueueActions(({ enqueue }) => {
                enqueue.sendTo(({ context }) => context.parentRef, { type: 'SET_DETAILS_LOADED' });
            })
        }
    }
});

export type QuestionMachine = typeof QuestionMachineDefinition;

export type QuestionActorRef = ActorRefFrom<typeof QuestionMachineDefinition>;
export type QuestionActor = Actor<typeof QuestionMachineDefinition>;
